import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/bookPageTemplate.js";
import Quote from "../../components/text-decorations/quote.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`These notes will be evergrowing as I read the book and take the video courses on the `}<a parentName="p" {...{
        "href": "https://ocw.mit.edu/courses/electrical-engineering-and-computer-science/6-001-structure-and-interpretation-of-computer-programs-spring-2005/index.htm"
      }}>{`MIT Open Courseware site`}</a>{`.
Feel free to reach out and chat about the book or any of the notes you may find here!`}</p>
    <p>Computational Processes -> Manipulate Data</p>
    <p>└> Evolution by a pattern of rules called program</p>
    <h2 {...{
      "id": "lisp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lisp",
        "aria-label": "lisp permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lisp`}</h2>
    <p>{`Includes new data objects:`}</p>
    <ul>
      <li parentName="ul">{`Atoms`}</li>
      <li parentName="ul">{`Lists`}</li>
    </ul>
    <p>{`Procedures are processes that can be represented and manipulated as Lisp data.`}</p>
    <Quote mdxType="Quote">
  The ability to represent procedures as data also makes Lisp and excellent
  language for writing programs that must manipulate other program's data, such
  as interpreters and compilers that support computer languages.
    </Quote>
    <h3 {...{
      "id": "expressions-using-primitive-procedures",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#expressions-using-primitive-procedures",
        "aria-label": "expressions using primitive procedures permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Expressions using primitive procedures`}</h3>
    <p>{`Basic math operations using list`}</p>
    <p>{`Addition`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(+ 137 349)
`}</code></pre>
    <p>{`Subtraction`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(- 1000 334)
`}</code></pre>
    <p>{`Multiplication`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(* 5 99)
`}</code></pre>
    <p>{`Division`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(/ 10 5)
`}</code></pre>
    <h2 {...{
      "id": "variables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#variables",
        "aria-label": "variables permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Variables`}</h2>
    <Quote mdxType="Quote">
  A critical aspect of a programming language is the means it provides for using
  names to refer to computational objects. We say that the name identifies a
  *variable* whose *value* is the object.
    </Quote>
    <p>{`In Lisp we can create variables using `}<strong parentName="p">{`define`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(define size 2)
`}</code></pre>
    <p>{`Defining the `}<em parentName="p">{`size`}</em>{` variable causes the interpreter to associate the value `}<inlineCode parentName="p">{`2`}</inlineCode>{` with the name `}<inlineCode parentName="p">{`size`}</inlineCode>{`. We can then use this variable by name:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(* 5 size)
`}</code></pre>
    <p>{`The possibility of associating values to variables and using them later means that the interpreter must maintain some memory that keeps track of name-object pairs. This memory is called the global environment.`}</p>
    <h3 {...{
      "id": "combinations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#combinations",
        "aria-label": "combinations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Combinations`}</h3>
    <p>{`Are expressions formed by delimiting a list of expressions within parentheses. The leftmost element in the list is called the `}<em parentName="p">{`operator`}</em>{` and the other elements are called the `}<em parentName="p">{`operands`}</em>{`.`}</p>
    <p>{`Combinations can be evaluated by:`}</p>
    <ul>
      <li parentName="ul">{`Evaluating the subexpression in the combination, for example with `}<inlineCode parentName="li">{`(* 2 (+ 1 1))`}</inlineCode>{` we will evaluate first `}<inlineCode parentName="li">{`(+1 1)`}</inlineCode></li>
      <li parentName="ul">{`Apply the procedure that is the value of the operator to the operands of the subexpression`}</li>
    </ul>
    <p>{`The evaluation rule is `}<em parentName="p">{`recursive`}</em>{` because we need to evaluate each subexpression in the combination to evaluate the whole combination.`}</p>
    <Quote mdxType="Quote">
  (...) recursion is a very powerful technique for dealing with hierarchical,
  treelike objects. In fact, the "percolate values upward" form of the
  evaluation rule is an example of a general kind of process known as *tree
  accumulation*.
    </Quote>
    <h3 {...{
      "id": "compound-procedures",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#compound-procedures",
        "aria-label": "compound procedures permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compound Procedures`}</h3>
    <p>{`We can create a procedure definition, by giving a name to a compound procedure and then referencing it as a unit. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(define (square x)(* x x))
`}</code></pre>
    <Quote mdxType="Quote">
  <p>The general form of a procedure definition is</p>
  <p>
    <code>(define (⟨name⟩ ⟨formal parameters⟩)⟨body⟩)</code>
  </p>
    </Quote>
We can now use these compound procedures to build other procedures for example:
    <pre><code parentName="pre" {...{
        "className": "language-lisp"
      }}>{`(define (sum-of-squares x y)
  (+ (square x) (square y)))
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      